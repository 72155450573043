<script
  setup
  lang="ts"
  generic="T extends 'prev' | 'next' | 'finish' | 'close' | 'copy'"
>
import type { ButtonHTMLAttributes } from 'vue';

interface Props {
  id?: string;
  variant: T;
  isDisabled?: T extends 'next' | 'finish' ? boolean : never;
  type?: ButtonHTMLAttributes['type'];
  onClick?: ButtonHTMLAttributes['onClick'];
}
const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  type: 'button',
  onClick: undefined,
});

const ariaLabel = computed(() => {
  switch (props.variant) {
    case 'prev':
      return '前へ';
    case 'next':
      return '次へ';
    case 'finish':
      return '回答終了';
    case 'close':
      return '閉じる';
    case 'copy':
      return 'コピーする';
    default:
      return '';
  }
});
const className = computed(() => {
  switch (props.variant) {
    case 'prev':
      return 'button button--prev';
    case 'next':
      return props.isDisabled
        ? 'button button--next button--disabled'
        : 'button button--next';
    case 'finish':
      return props.isDisabled
        ? 'button button--finish button--disabled'
        : 'button button--finish';
    case 'close':
      return 'button button--close';
    case 'copy':
      return 'button button--copy';
  }
});
</script>

<template>
  <button
    :id="props.id"
    :class="className"
    :aria-label="ariaLabel"
    :type="props.type"
    :aria-disabled="props.isDisabled"
    @click="props.onClick"
  />
</template>

<style scoped>
.button {
  display: inline-block;
  width: 160px;
  height: 58px;
  background-size: cover;
}

.button.button--prev {
  background-image: url('/images/button/small_button_prev.png');
}

.button.button--next {
  background-image: url('/images/button/small_button_next.png');
}

.button.button--next.button--disabled {
  background-image: url('/images/button/small_button_next_disabled.png');
}

.button.button--finish {
  background-image: url('/images/button/small_button_finish.png');
}

.button.button--finish.button--disabled {
  background-image: url('/images/button/small_button_finish_disabled.png');
}

.button.button--close {
  background-image: url('/images/button/small_button_close.png');
}

.button.button--copy {
  background-image: url('/images/button/small_button_copy.png');
}
</style>
