import { default as indexPMCJkF9I5xMeta } from "/home/circleci/project/src/pages/index.vue?macro=true";
import { default as completezUo5PNah0NMeta } from "/home/circleci/project/src/pages/question/complete.vue?macro=true";
import { default as indexSvgJPw3Ea5Meta } from "/home/circleci/project/src/pages/question/index.vue?macro=true";
import { default as indexBpC3hUPV5IMeta } from "/home/circleci/project/src/pages/result/index.vue?macro=true";
import { default as _91name_93ppsE75mJ31Meta } from "/home/circleci/project/src/pages/share/[score]/[ranking]/[level]/[name].vue?macro=true";
import { default as index23EvYvGkrDMeta } from "/home/circleci/project/src/pages/survey/complete/index.vue?macro=true";
import { default as index6PTo7hnN5MMeta } from "/home/circleci/project/src/pages/survey/index.vue?macro=true";
export default [
  {
    name: indexPMCJkF9I5xMeta?.name ?? "index",
    path: indexPMCJkF9I5xMeta?.path ?? "/",
    meta: indexPMCJkF9I5xMeta || {},
    alias: indexPMCJkF9I5xMeta?.alias || [],
    redirect: indexPMCJkF9I5xMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: completezUo5PNah0NMeta?.name ?? "question-complete",
    path: completezUo5PNah0NMeta?.path ?? "/question/complete",
    meta: completezUo5PNah0NMeta || {},
    alias: completezUo5PNah0NMeta?.alias || [],
    redirect: completezUo5PNah0NMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/question/complete.vue").then(m => m.default || m)
  },
  {
    name: indexSvgJPw3Ea5Meta?.name ?? "question",
    path: indexSvgJPw3Ea5Meta?.path ?? "/question",
    meta: indexSvgJPw3Ea5Meta || {},
    alias: indexSvgJPw3Ea5Meta?.alias || [],
    redirect: indexSvgJPw3Ea5Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/question/index.vue").then(m => m.default || m)
  },
  {
    name: indexBpC3hUPV5IMeta?.name ?? "result",
    path: indexBpC3hUPV5IMeta?.path ?? "/result",
    meta: indexBpC3hUPV5IMeta || {},
    alias: indexBpC3hUPV5IMeta?.alias || [],
    redirect: indexBpC3hUPV5IMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/result/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93ppsE75mJ31Meta?.name ?? "share-score-ranking-level-name",
    path: _91name_93ppsE75mJ31Meta?.path ?? "/share/:score()/:ranking()/:level()/:name()",
    meta: _91name_93ppsE75mJ31Meta || {},
    alias: _91name_93ppsE75mJ31Meta?.alias || [],
    redirect: _91name_93ppsE75mJ31Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/share/[score]/[ranking]/[level]/[name].vue").then(m => m.default || m)
  },
  {
    name: index23EvYvGkrDMeta?.name ?? "survey-complete",
    path: index23EvYvGkrDMeta?.path ?? "/survey/complete",
    meta: index23EvYvGkrDMeta || {},
    alias: index23EvYvGkrDMeta?.alias || [],
    redirect: index23EvYvGkrDMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/survey/complete/index.vue").then(m => m.default || m)
  },
  {
    name: index6PTo7hnN5MMeta?.name ?? "survey",
    path: index6PTo7hnN5MMeta?.path ?? "/survey",
    meta: index6PTo7hnN5MMeta || {},
    alias: index6PTo7hnN5MMeta?.alias || [],
    redirect: index6PTo7hnN5MMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/src/pages/survey/index.vue").then(m => m.default || m)
  }
]